<template>
  <div>
    <vx-card
      :title="`📷 Importador de imagenes por tipología ${project.name}.`"
      title-color="black"
    >
      <br />
    </vx-card>
    <vs-row>
      <vs-col class="mt-8" vs-justify="center" vs-align="center" vs-w="12">
        <ag-grid-vue
          class="ag-theme-material ag-grid-table h-screen w-100"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="getUnitTypes"
          :pagination="true"
          :paginationPageSize="itemsPerPage"
          :floatingFilter="true"
          :frameworkComponents="frameworkComponents"
          :rowHeight="150"
        ></ag-grid-vue>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api, unitTypeApi } from "../../projects/services";
import { AgGridVue } from "ag-grid-vue";
import AgImage from "../../../components/AgGridComponents/AgImage.vue";
import AgGroupButton from "../../../components/AgGridComponents/AgGroupButton.vue";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 5,
      project: null,
      getUnitTypes: [],
      defaultColDef: {
        filter: true,
        sortable: true,
        filterParams: {
          alwaysShowBothConditions: true,
          defaultJoinOperator: "OR"
        }
      },
      columnDefs: null
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  beforeMount() {
    this.frameworkComponents = {
      agImage: AgImage,
      agGroupButton: AgGroupButton
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.getUnitTypes = await unitTypeApi.list(this.project.id);

    this.columnDefs = [
      {
        headerName: "Plano",
        field: "project_unit_type_images",
        sortable: false,
        filter: false,
        cellRenderer: "agImage",
        cellRendererParams: params => {
          if (!params.data.project_unit_type_images.length) {
            return {
              src: "https://via.placeholder.com/150?Text=Vacio"
            };
          }

          const [mainImage] = params.data.project_unit_type_images;
          return {
            src: mainImage.image_url
          };
        }
      },
      {
        headerName: "Tipo",
        field: "type",
        width: 500
      },
      {
        headerName: "Acciones",
        field: "id",
        filter: false,
        sortable: false,
        width: 250,
        cellRenderer: "agGroupButton",
        cellRendererParams: () => {
          return {
            buttons: [
              {
                color: "primary",
                icon: "icon-edit",
                event: "edit-event"
              }
            ]
          };
        }
      }
    ];

    this.onAgGroupEventButton();
  },
  methods: {
    onAgGroupEventButton() {
      this.$root.$on("edit-event", data => {
        this.$router.push({
          name: "Importador de Imagenes",
          params: {
            projectUnitTypeId: data.id
          }
        });
      });
    }
  }
};
</script>
